import React, { Component, Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Provider } from 'redux-zero/react';
import { ThemeProvider } from 'styled-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Spinner } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';

import { library } from '@fortawesome/fontawesome-svg-core';

import { GlobalStyle, defaultTheme } from './theme/globalStyle';

import config from './config';
import { AbilityContext } from './config/Can';
import ability from './config/ability';
import store from './store';

import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faYoutube,
  faDiscord,
} from '@fortawesome/free-brands-svg-icons';

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 503) {
      toast.warning(
        <div>
          Service Unavailable!
          <br />
          {error?.response?.data ||
            `Could not complete action. Please try again later.`}
        </div>,
        {
          closeButton: true,
          autoClose: 5000,
          toastId: axios503ErrorToast,
        }
      );
    }

    return Promise.reject(error);
  }
);

const Home = React.lazy(() => import('./components/Home/Home'));
const UnityScene = React.lazy(() => import('./components/Unity/Scene'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    },
  },
});

class App extends Component {
  _setupFontAwesomeLibrary() {
    library.add(faEnvelope);
    library.add(faFacebook);
    library.add(faInstagram);
    library.add(faTwitter);
    library.add(faYoutube);
    library.add(faDiscord);
  }



  constructor(props) {
    super(props);

    this._setupFontAwesomeLibrary();

    // const subdomain = window.location.hostname;

    // this.state = {
    //   subdomain,
    // };
  }

  render() {
    console.log("RENDER APP")
    return (
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={defaultTheme}>
            <AbilityContext.Provider value={ability}>
              <DndProvider backend={HTML5Backend}>
                <GlobalStyle />
                <BrowserRouter>
                  <Suspense fallback={<Spinner />}>
                    <Switch>
                      <Route path="/" component={Home} />
                    </Switch>
                  </Suspense>
                </BrowserRouter>
              </DndProvider>
            </AbilityContext.Provider>
          </ThemeProvider>
        </QueryClientProvider>
      </Provider>
    );
  }
}

export default App;
