import createStore from 'redux-zero';
import { applyMiddleware } from 'redux-zero/middleware';

import initialState from './state';
import config from '../config';

let middlewares = [];

if (config.environment === 'development') {
  // eslint-disable-next-line global-require
  const { connect } = require('redux-zero/devtools');
  middlewares = connect && applyMiddleware(connect(initialState));
}

const store = createStore(initialState, middlewares);

export default store;
