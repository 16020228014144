import { Ability, AbilityBuilder } from '@casl/ability';
import roles from './roles.json';

const ability = new Ability();

const _createRules = role => {
  const { can, rules } = new AbilityBuilder(Ability);

  if (roles[role]) {
    for (const roleObject of roles[role]) {
      can(roleObject.action, roleObject.subject, roleObject.conditions);
    }
  }

  return rules;
};

export const updateRole = role => {
  ability.update(_createRules(role));
};

export default ability;
